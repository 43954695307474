@use '../styles/infogix-ui-variables' as *;

body {
  .p-togglebutton {
    &.p-button {
      border:none;
      background: var(--ig-grey-t5);
      color:var(--ig-black);
      &.p-button-icon-only {
        width:32px;
        height:32px;
        padding:0.429rem 1rem;
      }
      &.p-highlight {
        background: var(--ig-grey-t3);
        color:var(--ig-black);
        .p-button-icon-left {
          color:inherit;
        }
      }
      &.p-highlight:hover {
        border:none;
        background: var(--ig-grey-t4);
        color:var(--ig-black);
        .p-button-icon-left {
          color:inherit;
        }
      }
    }
    border:none;
    background: var(--ig-grey-t5);
    color:var(--ig-black);

    &:not(.p-disabled):not(.p-highlight):hover {
      border:none;
      background: var(--ig-grey-t4);
      color:var(--ig-black);
    }
  }
}