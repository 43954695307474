.ig-row {
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.ig-row-justify-start {
    justify-content: flex-start;
}

.ig-row-justify-center {
    justify-content: center;
}

.ig-row-justify-end {
    justify-content: flex-end;
}

.ig-row-align-start {
    align-items: flex-start;
}

.ig-row-align-center {
    align-items: center;
}

.ig-row-align-end {
    align-items: flex-end;
}

.ig-row-spacing-s > *:not(:last-child) {
    margin-right: 8px;
}

.ig-row-spacing-m > *:not(:last-child) {
    margin-right: 16px;
}

.ig-row-spacing-l > *:not(:last-child) {
    margin-right: 24px;
}

.ig-row-wrap {
    flex-wrap: wrap;

    &.ig-row-spacing-s > * {
        margin-bottom: 8px;
    }
    
    &.ig-row-spacing-m > * {
        margin-bottom: 16px;
    }
    
    &.ig-row-spacing-l > * {
        margin-bottom: 24px;
    }        
}

.ig-flex {
    flex:1;
}

.ig-no-flex {
    flex:0 !important;
}

.ig-flex2 {
    flex:2;
}

.ig-grow {
    flex-grow: 1;
}

.ig-shrink {
    flex-shrink: 1;
}