@import './color.scss';

$button-hover:$grey-t4;

$field-font-family: Precisely, sans-serif;
$bold-font-weight: 600 !default;

$focus-outline:$purple;
$focus-color: $purple-t3;
$focus-border-color: $purple;
$focus-width: 2px;

$field-border-radius: 4px;
$field-border-color: $grey-t3;
$field-border-color-hover: $grey-t1;

$field-height: 32px;
$field-disabled-opacity: 0.50;

$field-border: 1px solid $grey-t3;
$placeholder-color: $grey;

$panel-border-color:$grey-t3;
$panel-hover-bg-color: rgb(244, 244, 244);
$panel-focus-color:rgba($purple, 0.2);

$select-item-bg:$slate-t5;

$list-hover-bg-color:$purple-t5;
$list-item-focus-bg-color:$purple-t4;
$list-selected-bg-color:$purple-t4;

$table-header-bg-color:$grey-t5;
$table-header-border-color:$grey-t3;
$table-header-color:$black;

$required-bg:$lightblue-t3;
$required-border:#bdbfc6;

$error-bg:$red-xlight;
$error-border:$red-dark;
$error-color:$red;
