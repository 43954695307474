@use '../styles/infogix-ui-variables' as *;

body {
  .p-splitbutton {
    display: flex;
    .p-menu .p-menuitem-link {
      display: flex;
      .p-menuitem-text {
        white-space: nowrap;
      }
    }
    button.p-button {
      background-color: var(--ig-grey-t5);
      color: var(--ig-black);
      &:hover:not(:disabled) {
        background: var(--ig-grey-t4);
        color: var(--ig-black);
      }

      &:active:not(:disabled) {
        background: var(--ig-grey-t3);
        color: var(--ig-black);
      }
    }
  }
}