// Core Colors
$black: #202020;
$white: #ffffff;

$darkblue: #252c41;
$darkblue-light: #515667;
$darkblue-xlight: #676c7a;

$coral: #ff7155;
$coral-dark: #c44d36;
$coral-xlight: #ffc6bb;

$grey-dark: #4d565d;
$grey-light: #b9c0c5;
$grey-xlight: #dcdfe2;
$grey-xxlight: #f1f2f3;

$lightblue-t3:#d2eef4;

$grey:#6f7482;
$grey-t1: #898d99;
$grey-t2: #a0a3ad;
$grey-t3: #bdbfc6;
$grey-t4: #d7d8dc;
$grey-t5: #f1f1f3;
$grey-s1: #4c5263;
$grey-s2: #252c41;
$grey-s3: #1e2435;

$midblue: #006fba; /*#0971b9;*/
$midblue-light: #4093cb;
$midblue-xlight: #66a9d6;
$midblue-t1: mix(white, $midblue, 22%); // tweaked for contrast compliance
$midblue-t2: mix(white, $midblue, 36%);
$midblue-t3: mix(white, $midblue, 54%);
$midblue-t4: mix(white, $midblue, 72%);
$midblue-t5: mix(white, $midblue, 90%);
$midblue-s1: mix(black, $midblue, 18%);
$midblue-s2: mix(black, $midblue, 36%);
$midblue-s3: mix(black, $midblue, 54%);


$lightblue: #9ddae6;
$lightblue-light: #bde6ee;
$lightblue-xlight: #dff3f7;
$lightblue-t1: mix(white, $lightblue, 18%);
$lightblue-t2: mix(white, $lightblue, 36%);
$lightblue-t3: mix(white, $lightblue, 54%);
$lightblue-t4: mix(white, $lightblue, 72%);
$lightblue-t5: mix(white, $lightblue, 90%);
$lightblue-s1: mix(black, $lightblue, 18%);
$lightblue-s2: mix(black, $lightblue, 34%); // tweaked for contrast compliance
$lightblue-s3: mix(black, $lightblue, 54%);

$red: #d11947; /*#d11f49;*/
$red-dark: #b2153c; /*#b21a3e;*/
$red-xlight: #f3c5d1; /*#f3c7d1;*/
$red-t1: mix(white, $red, 14%); // #d73961
$red-t2: mix(white, $red, 36%); // #e26c89
$red-t3: mix(white, $red, 54%); // #ea95aa
$red-t4: mix(white, $red, 72%); // #f2bfcb
$red-t5: mix(white, $red, 90%); // #fae8ed
$red-s1: mix(black, $red, 18%); // #ab153a
$red-s2: mix(black, $red, 36%); // #86102d
$red-s3: mix(black, $red, 54%); // #600c21

$green: #00853e;
$green-dark: #007135;
$green-xlight: #bfe0cf;
$green-t1: mix(white, $green, 18%);
$green-t2: mix(white, $green, 36%);
$green-t3: mix(white, $green, 54%);
$green-t4: mix(white, $green, 72%);
$green-t5: mix(white, $green, 90%);
$green-s1: mix(black, $green, 18%);
$green-s2: mix(black, $green, 36%);
$green-s3: mix(black, $green, 54%);

$orange: #FFAA00;
$orange-dark: #d99600;
$orange-xlight: #ffe7b2;
$orange-t1: mix(white, $orange, 18%);
$orange-t2: mix(white, $orange, 36%);
$orange-t3: mix(white, $orange, 54%);
$orange-t4: mix(white, $orange, 72%);
$orange-t5: mix(white, $orange, 85%); // tweaked for balance
$orange-s1: mix(black, $orange, 18%);
$orange-s2: mix(black, $orange, 38%); // tweaked for contrast compliance
$orange-s3: mix(black, $orange, 54%);

$coral-t1: mix(white, $coral, 18%);
$coral-t2: mix(white, $coral, 36%);
$coral-t3: mix(white, $coral, 54%);
$coral-t4: mix(white, $coral, 72%);
$coral-t5: mix(white, $coral, 90%);
$coral-s1: mix(black, $coral, 14%); // tweaked for contrast compliance
$coral-s2: mix(black, $coral, 36%);
$coral-s3: mix(black, $coral, 54%);

$slate:#597897;
$slate-t1:#7690a9;
$slate-t2:#94a8bc;
$slate-t3:#b2c1cf;
$slate-t4:#d0d9e2;
$slate-t5:#eef1f4;
$slate-s1:#48627b;
$slate-s2:#384c60;
$slate-s3:#283745;


$purple: #6d18dd;
$purple-t1: #9d53ff;
$purple-t2: #b984ff;
$purple-t3: #d4b4ff;
$purple-t4: #e4cfff;
$purple-t5: #f5eeff;
$purple-s3: #36017b;

$pink: #962470;
$pink-t1: #ce53a5;
$pink-t3: #e2aed0;
$pink-s3: #6a0047;