.ig-column {
    display: inline-flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.ig-column-justify-start {
    justify-content: flex-start;
}

.ig-column-justify-center {
    justify-content: center;
}

.ig-column-justify-end {
    justify-content: flex-end;
}

.ig-column-align-start {
    align-items: flex-start;
}

.ig-column-align-center {
    align-items: center;
}

.ig-column-align-end {
    align-items: flex-end;
}

.ig-column-spacing-s > *:not(:last-child) {
    margin-bottom: 8px;
}

.ig-column-spacing-m > *:not(:last-child) {
    margin-bottom: 16px;
}

.ig-column-spacing-l > *:not(:last-child) {
    margin-bottom: 24px;
}

.ig-column-wrap {
    flex-wrap: wrap;

    &.ig-column-spacing-s > * {
        margin-right: 8px;
    }
    
    &.ig-column-spacing-m > * {
        margin-right: 16px;
    }
    
    &.ig-column-spacing-l > * {
        margin-right: 24px;
    }        
}
