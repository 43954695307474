@font-face {
  font-family: 'Precisely';
  src: url("./Precisely-Thin.ttf");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Precisely';
  src: url("./Precisely-ThinIt.ttf");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Precisely';
  src: url("./Precisely-Light.ttf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Precisely';
  src: url("./Precisely-LightIt.ttf");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Precisely";
  src: url("./Precisely-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Precisely";
  src: url("./Precisely-RegularIt.ttf");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Precisely";
  src: url("./Precisely-Bold.ttf");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Precisely";
  src: url("./Precisely-BoldIt.ttf");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Precisely';
  src: url("./Precisely-ExtraBold.ttf");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Precisely';
  src: url("./Precisely-ExtraBoldIt.ttf");
  font-weight: 800;
  font-style: italic;
}