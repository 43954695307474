@layer primeng {
    * {
        margin: 0;
    }
}
.ig-margin-xs { margin: 4px; }
.ig-margin-s { margin: 8px; }
.ig-margin-m { margin: 16px; }
.ig-margin-l { margin: 32px; }

.ig-margin-xs-t { margin-top: 4px; }
.ig-margin-s-t { margin-top: 8px; }
.ig-margin-m-t { margin-top: 16px; }
.ig-margin-l-t { margin-top: 32px; }

.ig-margin-xs-b { margin-bottom: 4px; }
.ig-margin-s-b { margin-bottom: 8px; }
.ig-margin-m-b { margin-bottom: 16px; }
.ig-margin-l-b { margin-bottom: 32px; }

.ig-margin-xs-l { margin-left: 4px; }
.ig-margin-s-l { margin-left: 8px; }
.ig-margin-m-l { margin-left: 16px; }
.ig-margin-m2-l { margin-left: 24px; }
.ig-margin-l-l { margin-left: 32px; }

.ig-margin-xs-r { margin-right: 4px; }
.ig-margin-s-r { margin-right: 8px; }
.ig-margin-m-r { margin-right: 16px; }
.ig-margin-l-r { margin-right: 32px; }

.ig-margin-xs-h { margin-left: 4px; margin-right: 4px; }
.ig-margin-s-h { margin-left: 8px; margin-right: 8px; }
.ig-margin-m-h { margin-left: 16px; margin-right: 16px; }
.ig-margin-l-h { margin-left: 32px; margin-right: 32px; }

.ig-margin-xs-v { margin-top: 4px; margin-bottom: 4px; }
.ig-margin-s-v { margin-top: 8px; margin-bottom: 8px; }
.ig-margin-m-v { margin-top: 16px; margin-bottom: 16px; }
.ig-margin-l-v { margin-top: 32px; margin-bottom: 32px; }
