@use './infogix-ui-variables' as *;
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i&display=swap');
@import url('https://fonts.googleapis.com/css?family=Source+Code+Pro&display=swap');

$font-family: Precisely !default;
$font-weight: normal !default;
$font-size: 14px !default;
$text-color: var(--ig-black) !default;

body {
    font-family: $font-family !important;
    font-weight: $font-weight;
    font-size: $font-size;
    color: $text-color;
    .pi {
        font-size:14px;
    }

    button, input, .p-component {
        font-size:$font-size;
        font-family: $font-family !important;
        font-weight: $font-weight;
    }


}

.ig-title {
    font-size: 18px;
    font-weight: var(--ig-bold-font-weight);
}

.ig-subtitle {
    font-size: 14px;
    font-weight: var(--ig-bold-font-weight);
}

.ig-advisory {
    font-size: 14px;
    color: var(--ig-grey-t3);
}

a, .ig-link {
    color: var(--clickable-link-color);
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}

.ig-clickable {
    cursor:pointer
}

.ig-bold {
    font-weight: var(--ig-bold-font-weight);
}

.ig-code {
    font-family: 'Source Code Pro', monospace;
    font-weight: normal;
    font-size: $font-size;
    color: $text-color;
}

.ig-wrap {
    overflow-wrap: break-word;
    word-break: normal;
    overflow-x: hidden;
}

pre.ig-wrap {
    white-space: pre-wrap;
    overflow-wrap: break-word;
    word-break: normal;
    overflow-x: hidden;
}

.ig-elide {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
}

.ig-nowrap {
    white-space: nowrap;
}

.ig-elide-front {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
    direction: rtl;
    text-align: left;
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    &.bulleted {
        list-style-type: disc;
        margin-left: 18px;
    }
}
