@use './infogix-ui-variables' as *;
.ig-form-section {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  > .ig-field-h-ct:not(:last-child),
  > .ig-field-v-ct:not(:last-child) {
    margin-bottom:16px;
  }
  &.ig-form-section-content {
    border: none;
    padding-top: 8px;
    padding-bottom: 32px;
    padding-left: 22px;
    //border-bottom: 1px solid var(--ig-grey-t4);
  }
}

.ig-field-h-ct {
  display:flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  > *:not(:last-child) {
    margin-right:8px;
    &.medium-space {
      margin-right:24px;
    }
  }

  &.ig-stretch {
    align-items: stretch;
  }

  &.ig-start {
    align-items:start;
  }

  .ig-advisory {
    margin-left: 8px;
    flex-shrink: 1;
  }
}

.ig-field-v-ct {
  display:flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;

  &.ig-stretch {
    align-items: stretch;
  }

  > label,  > ig-field-label {
    margin-bottom: 4px;
    height: 16px;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
  }
  .ig-feedback {
    margin-top:5px;
  }
}

.ig-field-label {
  width: 200px;
}

.ig-read-only {
  background-color: #F1F2F3 !important;
}

.ig-form-field {
  display:inline-flex;
  box-sizing: border-box;
  background: white;
  min-height: 32px;
  border-radius: 3px;
  flex-direction: column;
  align-items: stretch;
  border:none;

  .p-inputtext, label.p-chkbox-label,
  .ui-spinner, .ui-spinner-input.ui-inputtext {
    width: 100%;
  }

  &.ig-grow {
    .ui-spinner {
      width:100%
    }
  }
}

.ig-field-group {
  background-color:var(--ig-grey-t5);
  padding: 10px;
  border-radius: 4px;
  .ig-form-field {
    background-color: transparent;border:none;
  }
}

.ig-form-header-text {
  height: 16px;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  flex-grow: 1;
}

.ig-form-section-header {
  padding:8px 0;
  width: 100%;
  display: inline-flex;
  align-items: center;
}
