.ig-width-128 {
    width: 128px;
}

.ig-maxw-1024 {
    max-width: 1024px;
}

.ig-w-100pc, .ig-w-fullw {
    width: 100%;
}

.ig-no-minw {
    min-width: 0;
}

//12 col grid
.ig-w-c1 {width: 8.33%;}
.ig-w-c2 {width: 16.66%;}
.ig-w-c3 {width: 25%;}
.ig-w-c4 {width: 33.33%;}
.ig-w-c5 {width: 41.66%;}
.ig-w-c6 {width: 50%;}
.ig-w-c7 {width: 58.33%;}
.ig-w-c8 {width: 66.66%;}
.ig-w-c9 {width: 75%;}
.ig-w-c10 {width: 83.33%;}
.ig-w-c11 {width: 91.66%;}
.ig-w-c12 {width: 100%;}

.ig-w100 {width: 100px; }
.ig-w175 {width: 175px;}
.ig-w200 {width: 200px; }
.ig-w300 {width: 300px; }
.ig-min-w300 {min-width: 300px; }
.ig-w400 {width: 400px; }
.ig-w600 {width: 600px; }
