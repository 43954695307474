@layer primeng {
    * {
        padding: 0;
    }
}

.ig-padding-s { padding: 8px; }
.ig-padding-m { padding: 16px; }
.ig-padding-l { padding: 32px; }

.ig-padding-s-t { padding-top: 8px; }
.ig-padding-m-t { padding-top: 16px; }
.ig-padding-l-t { padding-top: 32px; }

.ig-padding-s-b { padding-bottom: 8px; }
.ig-padding-m-b { padding-bottom: 16px; }
.ig-padding-l-b { padding-bottom: 32px; }

.ig-padding-s-l { padding-left: 8px; }
.ig-padding-m-l { padding-left: 16px; }
.ig-padding-l-l { padding-left: 32px; }

.ig-padding-s-r { padding-right: 8px; }
.ig-padding-m-r { padding-right: 16px; }
.ig-padding-l-r { padding-right: 32px; }

.ig-padding-s-h { padding-left: 8px; padding-right: 8px; }
.ig-padding-m-h { padding-left: 16px; padding-right: 16px; }
.ig-padding-l-h { padding-left: 32px; padding-right: 32px; }

.ig-padding-s-v { padding-top: 8px; padding-bottom: 8px; }
.ig-padding-m-v { padding-top: 16px; padding-bottom: 16px; }
.ig-padding-l-v { padding-top: 32px; padding-bottom: 32px; }
