@use '../styles/infogix-ui-variables' as *;
@import "~primeng/resources/components/menubar/menubar.css";
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus,
.p-menubar .p-menuitem-link:focus {
  // changed to 1px for focus outline
  box-shadow: inset 0 0 0 1px var(--focus-color);
}
.p-menubar .p-submenu-list .p-menuitem-link .p-submenu-icon {
  position: absolute;
  right:0;
}
.p-menubar .p-submenu-list {
  width:unset;
  min-width: 12.5rem;
  max-width: 30rem;
}
body .p-menubar .p-menuitem-link {
  padding:0.857rem; // from original PrimeNG 16.0
}

body .p-menu-separator {
  border-top: 1px solid #d8dae2;
  margin: 0;
}
