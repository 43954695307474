@use "../../../../../../angular-ui-framework/angular/src/components/styles/infogix-ui-variables" as *;
@use "../../../../../../angular-ui-framework/angular/src/components/styles/mixin/infogix-mixin" as *;

$collapsed-panel-width:48px;

.dqp-view {
  margin: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  overflow: auto;
  background-color: var(--ig-white);

  .north-panel {
    padding: 0;
    margin: 0;
    background: var(--ig-grey-t5);

    .north-panel-content {
      margin: 16px 16px 0 16px;
      background: var(--ig-white);
      padding: 16px;
      border-radius: 4px;
    }
  }

  .south-panel {
    padding: 0;
    margin: 0;
    background: var(--ig-grey-t5);
    flex-wrap: nowrap;
    flex: 1;
    overflow: auto;
    align-items: stretch
  }
  .as-split-area {
    .left-panel {
      padding:8px;
      margin-right:0;
    }
    .right-panel {
      padding: 8px;
      margin-left:0;
    }
  }
  .left-panel {
    background: var(--ig-white);
    margin: 16px;
    border-radius: 4px;
    min-width: 350px;
    overflow: auto;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
    &.collapsible {
      min-width: $collapsed-panel-width;
      .expand-button {
        display: none;
      }
    }
    &.collapsed {
      width:$collapsed-panel-width;
      .left-panel-content {
        display:none;
      }
      .expand-button {
        display: flex;
      }
    }
    .left-panel-content {
      display: flex;
      flex-direction: column;
      height: 100%
    }
  }

  .right-panel {
    padding: 16px;
    border-radius: 4px;
    background-color: var(--ig-white);
    margin: 16px 16px 16px 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex-wrap: nowrap;
    min-width: 0;
    flex: 1;
  }
}

.dqp-toolbar {
  display: flex;
  padding: 0 4px 0 4px;
  align-items: center;

  &.ig-padding-m-t {
    padding-top: 16px;
  }

  > *:not(:last-child) {
    margin: auto 8px auto 0;
  }

  &.top-header {
    height: 48px;
    padding-left: 16px;
  }

  .dqp-title-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
    background-color: var(--breadcrumb-icon-bg-color);
    color: var(--ig-white);
    border-radius: 2px;

    .fa {
      height: 16px;
      width: 16px;
      line-height: 16px;
      text-align: center;
      font-size: 18px;
    }
  }

  .ig-title {
    font-weight: normal;
    font-size: 14px;
    display: inline-flex;
    align-items: center;

    .fa {
      font-size: 10px;
      margin: auto 4px;
    }
  }
}

.label-ct {
  > label {
    font-weight: 600;
  }
}

.dqp-menu-icon {
  display: inline-block;
  height: 16px;
  text-align: center;
  background-repeat: no-repeat;
  background-position: 0 center;
  vertical-align: middle;
}

.dqp-view-property-v-ct {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 16px;

  .name {
    font-weight: var(--ig-bold-font-weight);
  }

  .value {
    font-weight: normal;
  }
}

.dqp-view-property-v-ct {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 16px;

  .name {
    font-weight: var(--ig-bold-font-weight);
  }

  .value {
    font-weight: normal;
  }
}

.dqp-toolbar-separator {
  height: 32px;
  border-left: 1px solid var(--ig-grey-t4);
}

.dqp-open-in-new-tab-tip {
  color: var(--ig-grey-t2);
}