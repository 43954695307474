@use 'infogix-ui-variables' as *;
.ig-white {
    background: var(--ig-white);
    color: var(--ig-black);
}

.ig-black {
    background: var(--ig-black);
    color: var(--ig-white);
}

.ig-grey {
    background: var(--ig-grey);
    color: var(--ig-white);
}

.ig-grey-t1 {
    background: var(--ig-grey-t1);
    color: var(--ig-black);
}

.ig-grey-t2 {
    background: var(--ig-grey-t2);
    color: var(--ig-black);
}

.ig-grey-t3 {
    background: var(--ig-grey-t3);
    color: var(--ig-black);
}

.ig-grey-t4 {
    background: var(--ig-grey-t4);
    color: var(--ig-black);
}

.ig-grey-t5 {
    background: var(--ig-grey-t5);
    color: var(--ig-black);
}

.ig-grey-s1 {
    background: var(--ig-grey-s1);
    color: var(--ig-white);
}

.ig-grey-s2 {
    background: var(--ig-grey-s2);
    color: var(--ig-white);
}

.ig-grey-s3 {
    background: var(--ig-grey-s3);
    color: var(--ig-white);
}

.ig-midblue {
    background: $midblue;
    color: var(--ig-white);
}

.ig-midblue-t1 {
    background: $midblue-t1;
    color: var(--ig-black);
}

.ig-midblue-t2 {
    background: $midblue-t2;
    color: var(--ig-black);
}

.ig-midblue-t3 {
    background: $midblue-t3;
    color: var(--ig-black);
}

.ig-midblue-t4 {
    background: $midblue-t4;
    color: var(--ig-black);
}

.ig-midblue-t5 {
    background: $midblue-t5;
    color: var(--ig-black);
}

.ig-midblue-s1 {
    background: $midblue-s1;
    color: var(--ig-white);
}

.ig-midblue-s2 {
    background: $midblue-s2;
    color: var(--ig-white);
}

.ig-midblue-s3 {
    background: $midblue-s3;
    color: var(--ig-white);
}

.ig-lightblue {
    background: $lightblue;
    color: var(--ig-black);
}

.ig-lightblue-t1 {
    background: $lightblue-t1;
    color: var(--ig-black);
}

.ig-lightblue-t2 {
    background: $lightblue-t2;
    color: var(--ig-black);
}

.ig-lightblue-t3 {
    background: $lightblue-t3;
    color: var(--ig-black);
}

.ig-lightblue-t4 {
    background: $lightblue-t4;
    color: var(--ig-black);
}

.ig-lightblue-t5 {
    background: $lightblue-t5;
    color: var(--ig-black);
}

.ig-lightblue-s1 {
    background: $lightblue-s1;
    color: var(--ig-black);
}

.ig-lightblue-s2 {
    background: $lightblue-s2;
    color: var(--ig-black);
}

.ig-lightblue-s3 {
    background: $lightblue-s3;
    color: var(--ig-white);
}

.ig-red {
    background: $red;
    color: var(--ig-white);
}

.ig-red-t1 {
    background: $red-t1;
    color: var(--ig-white);
}

.ig-red-t2 {
    background: $red-t2;
    color: var(--ig-black);
}

.ig-red-t3 {
    background: $red-t3;
    color: var(--ig-black);
}

.ig-red-t4 {
    background: $red-t4;
    color: var(--ig-black);
}

.ig-red-t5 {
    background: $red-t5;
    color: var(--ig-black);
}

.ig-red-s1 {
    background: $red-s1;
    color: var(--ig-white);
}

.ig-red-s2 {
    background: $red-s2;
    color: var(--ig-white);
}

.ig-red-s3 {
    background: $red-s3;
    color: var(--ig-white);
}

.ig-green {
    background: $green;
    color: var(--ig-white);
}

.ig-green-t1 {
    background: $green-t1;
    color: var(--ig-black);
}

.ig-green-t2 {
    background: $green-t2;
    color: var(--ig-black);
}

.ig-green-t3 {
    background: $green-t3;
    color: var(--ig-black);
}

.ig-green-t4 {
    background: $green-t4;
    color: var(--ig-black);
}

.ig-green-t5 {
    background: $green-t5;
    color: var(--ig-black);
}

.ig-green-s1 {
    background: $green-s1;
    color: var(--ig-white);
}

.ig-green-s2 {
    background: $green-s2;
    color: var(--ig-white);
}

.ig-green-s3 {
    background: $green-s3;
    color: var(--ig-white);
}

.ig-orange {
    background: $orange;
    color: var(--ig-black);
}

.ig-orange-t1 {
    background: $orange-t1;
    color: var(--ig-black);
}

.ig-orange-t2 {
    background: $orange-t2;
    color: var(--ig-black);
}

.ig-orange-t3 {
    background: $orange-t3;
    color: var(--ig-black);
}

.ig-orange-t4 {
    background: $orange-t4;
    color: var(--ig-black);
}

.ig-orange-t5 {
    background: $orange-t5;
    color: var(--ig-black);
}

.ig-orange-s1 {
    background: $orange-s1;
    color: var(--ig-black);
}

.ig-orange-s2 {
    background: $orange-s2;
    color: var(--ig-white);
}

.ig-orange-s3 {
    background: $orange-s3;
    color: var(--ig-white);
}

.ig-coral {
    background: $coral;
    color: var(--ig-black);
}

.ig-coral-t1 {
    background: $coral-t1;
    color: var(--ig-black);
}

.ig-coral-t2 {
    background: $coral-t2;
    color: var(--ig-black);
}

.ig-coral-t3 {
    background: $coral-t3;
    color: var(--ig-black);
}

.ig-coral-t4 {
    background: $coral-t4;
    color: var(--ig-black);
}

.ig-coral-t5 {
    background: $coral-t5;
    color: var(--ig-black);
}

.ig-coral-s1 {
    background: $coral-s1;
    color: var(--ig-black);
}

.ig-coral-s2 {
    background: $coral-s2;
    color: var(--ig-white);
}

.ig-coral-s3 {
    background: $coral-s3;
    color: var(--ig-white);
}
